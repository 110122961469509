/**
 * Fields of the object passed into {@link PCDPackage.prove} can only represent
 * one of the following types. {@link Unknown} is included to be used in a similar
 * way as `unknown` is used in TypeScript.
 */
export var ArgumentTypeName;
(function (ArgumentTypeName) {
    ArgumentTypeName["String"] = "String";
    ArgumentTypeName["Number"] = "Number";
    ArgumentTypeName["BigInt"] = "BigInt";
    ArgumentTypeName["Boolean"] = "Boolean";
    ArgumentTypeName["Object"] = "Object";
    ArgumentTypeName["StringArray"] = "StringArray";
    ArgumentTypeName["PCD"] = "PCD";
    ArgumentTypeName["RecordContainer"] = "RecordContainer";
    ArgumentTypeName["ToggleList"] = "ToggleList";
    ArgumentTypeName["Unknown"] = "Unknown";
})(ArgumentTypeName || (ArgumentTypeName = {}));
export function isRecordContainerArgument(arg) {
    return arg.argumentType === ArgumentTypeName.RecordContainer;
}
export function isStringArgument(arg) {
    return arg.argumentType === ArgumentTypeName.String;
}
export function isNumberArgument(arg) {
    return arg.argumentType === ArgumentTypeName.Number;
}
export function isBigIntArgument(arg) {
    return arg.argumentType === ArgumentTypeName.BigInt;
}
export function isBooleanArgument(arg) {
    return arg.argumentType === ArgumentTypeName.Boolean;
}
export function isObjectArgument(arg) {
    return arg.argumentType === ArgumentTypeName.Object;
}
export function isStringArrayArgument(arg) {
    return arg.argumentType === ArgumentTypeName.StringArray;
}
export function isPCDArgument(arg) {
    return arg.argumentType === ArgumentTypeName.PCD;
}
export function isToggleListArgument(arg) {
    return (arg.argumentType === ArgumentTypeName.ToggleList &&
        arg.value !== undefined &&
        arg.value !== null &&
        typeof arg.value === "object" &&
        Object.values(arg.value).every((v) => typeof v === "boolean"));
}
export function isRevealListArgument(arg) {
    return (arg.value !== undefined &&
        Object.keys(arg.value).every((k) => k.startsWith("reveal")));
}
